// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-test-automation-js": () => import("./../../../src/pages/test-automation.js" /* webpackChunkName: "component---src-pages-test-automation-js" */),
  "component---src-templates-about-us-template-js": () => import("./../../../src/templates/AboutUsTemplate.js" /* webpackChunkName: "component---src-templates-about-us-template-js" */),
  "component---src-templates-career-job-template-js": () => import("./../../../src/templates/CareerJobTemplate.js" /* webpackChunkName: "component---src-templates-career-job-template-js" */),
  "component---src-templates-career-template-js": () => import("./../../../src/templates/CareerTemplate.js" /* webpackChunkName: "component---src-templates-career-template-js" */),
  "component---src-templates-case-studies-template-js": () => import("./../../../src/templates/CaseStudiesTemplate.js" /* webpackChunkName: "component---src-templates-case-studies-template-js" */),
  "component---src-templates-case-study-template-js": () => import("./../../../src/templates/CaseStudyTemplate.js" /* webpackChunkName: "component---src-templates-case-study-template-js" */),
  "component---src-templates-contact-template-js": () => import("./../../../src/templates/ContactTemplate.js" /* webpackChunkName: "component---src-templates-contact-template-js" */),
  "component---src-templates-digital-service-template-js": () => import("./../../../src/templates/DigitalServiceTemplate.js" /* webpackChunkName: "component---src-templates-digital-service-template-js" */),
  "component---src-templates-digital-services-template-js": () => import("./../../../src/templates/DigitalServicesTemplate.js" /* webpackChunkName: "component---src-templates-digital-services-template-js" */),
  "component---src-templates-eshop-promotional-template-js": () => import("./../../../src/templates/EshopPromotionalTemplate.js" /* webpackChunkName: "component---src-templates-eshop-promotional-template-js" */),
  "component---src-templates-general-template-js": () => import("./../../../src/templates/GeneralTemplate.js" /* webpackChunkName: "component---src-templates-general-template-js" */),
  "component---src-templates-home-template-js": () => import("./../../../src/templates/HomeTemplate.js" /* webpackChunkName: "component---src-templates-home-template-js" */),
  "component---src-templates-qr-code-template-js": () => import("./../../../src/templates/QrCodeTemplate.js" /* webpackChunkName: "component---src-templates-qr-code-template-js" */),
  "component---src-templates-service-template-js": () => import("./../../../src/templates/ServiceTemplate.js" /* webpackChunkName: "component---src-templates-service-template-js" */),
  "component---src-templates-services-template-js": () => import("./../../../src/templates/ServicesTemplate.js" /* webpackChunkName: "component---src-templates-services-template-js" */),
  "component---src-templates-website-promo-template-js": () => import("./../../../src/templates/WebsitePromoTemplate.js" /* webpackChunkName: "component---src-templates-website-promo-template-js" */)
}

